
@import "~@fontsource/m-plus-rounded-1c/index.css";

body {
    font-family: "M PLUS Rounded 1c", sans-serif;
}
/* In your CSS file or component styles */


.topmost {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-items: center;
  align-items: center;
  padding-top: 3%;
  background: rgb(0, 0, 0);
  background: linear-gradient(300deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(255, 239, 239, 0.6125043767507004) 100%);
}

.first {
  margin-top: 20px;
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: center;
}

.innerfirst {

  display: flex;
  flex-direction: column;
  max-width: 100%;
  align-items: left;
  margin-left: 10%;
}

.get {
  color: 'black';
  font-Size: 52px;
  font-style: normal;
  font-Weight: 300;
  font-family: 'Roboto', sans-serif;
}

.getxt {
  color: 'black';
  font-Size: 16px;
  font-weight: 300;
  font-style: normal;
  font-Weight: '10';
  margin-top: -22px;
  /* font-family: 'Roboto', sans-serif; */
}

.firstbtn {
  /* font-family: 'Roboto', sans-serif; */
  width: fit-content;
  height: fit-content;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 11px;
  padding-bottom: 11px;
  background: #8AB9DA;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  font-size: 13.33px;
  font-weight: 500;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
  border: none;
  outline: none;
}

.innerimgfirst {

  display: flex;
  flex-direction: row;
  max-width: 100%;
  align-items: center;
  margin-right: 20%;


}

.imagedog {
  max-width: 100%;
  align-items: center;
  margin-left: 30%;
  height: auto;
  width: auto;
  padding-right: 300px;


}

.firstbtn {
  justify-content: left;
  max-width: 35%;

  /* font-family: 'Roboto', sans-serif; */
}

.head2 {
  /* margin-top: 20px;
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: center;
  color: 'black';
  font-Size: 40px;
  font-style: normal;
  font-Weight: '300'; */
  padding-top: 80px;
  /* font-family: 'Roboto', sans-serif; */
  padding-bottom: 30px;
  color: black;
  font-size: 30px;
  font-weight: 300;
  word-wrap: break-word;
  text-align: center;
  line-height: 1.2;
  margin-top: 20px;
  margin-bottom: 20px;
}

.contactbox {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #FDF2F2;
  margin-left: 20%;
  margin-right: 20%;
  border-radius: 30px;
  padding: 20px;
}

.specialtxt {
  color: #EDB5B2;
  font-size: 52px;
  /* font-family: 'Roboto', sans-serif; */
  margin-top: -20px;
  font-weight: 300;


}

.spltxt {
  color: #000000;
  font-size: 16px;
  /* font-family: 'Roboto', sans-serif; */
  margin-top: -20px;

}

.contactside {
  display: flex;
  flex-direction: column;
  max-width: 70%;
  align-items: left;
  justify-content: center;
  padding-left: 20px;

}

.contactFormContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  padding-right: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 50px;
}

.contactForm {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  align-items: left;
  justify-content: center;
  gap: 12px;
  border-radius: 30px;
  background: #FDF2F2;

}

.fname {
  border-radius: 8px;
  border: solid 1px #daa6a3;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 20px;
  width: 300px;

}

.fbtn {
  margin-top: 10px;
  /* font-family: 'Roboto', sans-serif; */
  width: fit-content;
  background-color: #EDB5B2;
  border: none;
  border-radius: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 19px;
  padding-left: 19px;
  font-size: 12px;
  color: white;


}

.callcon {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  /* font-family: 'Roboto', sans-serif; */

}

.callsec {
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  /* font-family: 'Roboto', sans-serif; */
}

.grid {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}

.innergrid {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  align-items: left;
  justify-content: center;
}

.socialimg {
  max-width: 70px;
  display: inline-block;
  padding-right: 50px;
  vertical-align: top;

  /* Align the image to the top */

}

.vector {
  max-width: 50%;
  justify-content: left;
}

.vector2 {
  max-width: 50%;
  justify-content: right;
  padding-left: 50%;
}

.innerin {
  display: flex;
  flex-direction: row;
  gap: 40px;
  
}

.add {
  color: 'black';
  font-Size: 16px;
  font-style: normal;
  font-Weight: 300;
  margin-left: -150px;
  /* font-family: 'Roboto', sans-serif; */

}

.mail {
  color: 'black';
  font-Size: 16px;
  font-style: normal;
  font-Weight: 300;
  margin-left: 0px;
  /* font-family: 'Roboto', sans-serif; */

}

.social {
  color: 'black';
  display: flex;
  flex-direction: column;
  font-Size: 16px;
  font-style: normal;
  font-Weight: 300;
  align-items: center;
  margin-top: 40px;
  margin-left: -60px;
  margin-right: 40px;
  /* font-family: 'Roboto', sans-serif; */
}

.innersocail {
  display: flex;
  max-width: 100%;
  align-items: center;
  justify-content: center;

}

.map_part {
  margin-top: 30px;
  display: flex;
  max-width: 100%;
  align-items: center;
  justify-content: center;

  border-radius: 30px;
  padding: 10px;
  width: 700vh ;
  height:300px;
}

.timecss {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  align-items: center;
  /* Center horizontally */
  justify-content: center;
  margin-top: 40px;
}

.thead {
  color: black;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  /* font-family: 'Roboto', sans-serif; */
}

.time {
  margin-top: -10px;
  color: black;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  /* font-family: 'Roboto', sans-serif; */
  margin-bottom: 60px;
}

@media (max-width: 768px) {
  .topmost {
      padding-top: 1%;
      margin-bottom: -70px;
  }

  .first {
      flex-direction: column;
      align-items: center;
  }

  .innerfirst {
      max-width: 80%;
      margin-left: 0;
      text-align: center;
  }

  .get {
      font-size: 27px;
  }

  .getxt {
      font-size: 14px;
      margin-top: -10px;
  }

  .firstbtn {
      max-width: 100%;
      margin-top: 10px;
      margin-left: 0;
      margin-bottom: 15px;
  }

  .innerimgfirst {
      max-width: 80%;
      margin-right: 0;
      margin-left: 0;
  }

  .imagedog {
      max-width: 100%;
      margin-left: 0;
      padding-right: 0;
  }

  .head2 {
      padding-top: 40px;
      font-size: 27px;
      padding-left: 20px;
      padding-right: 20px;
  }

  .contactbox {
      flex-direction: column;
      margin-left: 5%;
      margin-right: 5%;
  }

  .contactside {
      max-width: 100%;
      padding-left: 0;
      text-align: center;
      align-items: center;
  }

  .specialtxt {
      font-size: 30px;
      margin-top: 0px;
  }

  .spltxt {
      font-size: 14px;
      margin-top: 0px;
  }

  .contactFormContainer {
      padding-left: 0px;
      padding-right: 0px;
  }
  .contactForm{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .fname {
      width: 300px;
  }

  .fbtn {
      max-width: 100%;
  }

  .callcon {
      margin-top: 30px;
      margin-bottom: 30px;
  }

  .callsec {
      font-size: 24px;
  }

  .grid {
      flex-direction: column;
      margin-left: 10%;
      margin-right: 10%;
      align-items: center;
  }

  .innergrid {
      max-width: 100%;
      align-items: center;
  }

  .vector, .vector2 {
      display: none;
  }

  .innerin {
      flex-direction: column;
      gap: 20px;
  }

  .add, .mail {
      font-size: 14px;
      margin-left: 0;
      text-align: center;
      background-color: #FDF2F2;
      border-radius: 20px;
      padding: 15px;
  }

  .social {
      font-size: 14px;
      margin-top: 20px;
      margin-left: 0px;
      margin-right: 0px;
      text-align: center;
      background-color: #FDF2F2;
      border-radius: 20px;
      padding: 20px;
  }

  .socialimg {
      max-width: 70px;
      margin: 0px;
      padding-right: 0px;
  }

  .map_part {
      margin-top: 20px;
      width: 300px;
      height:200px
  }

  .timecss {
      margin-top: 20px;
      margin-bottom: 20px;
  }

  .thead {
      font-size: 18px;
  }

  .time {
      font-size: 14px;
      margin-top: -5px;
      margin-bottom: 40px;
  }
}
